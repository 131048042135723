<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('Settings')"
      icon="mdi-cog"
      :header-action="saveChanges"
      :header-action-text="$t('Save')"
  >

    <v-sheet class="mt-6">

      <ws-accordion
          :items="[{name : $t('Link') , subtitle : $t('RegistrationLinkDescription') , expand : false, expanded : false,  value: 'link' , icon: 'mdi-link' , noExpand : true }]"
          class="mb-3"
      >
        <template #action.link>
          <v-btn @click="copyRegistrationLink" class="noCaps px-3 mr-3"  :color="wsACCENT" text>
            {{ $t('RegistrationLink') }}
            <v-icon class="ml-2">mdi-content-copy</v-icon>
          </v-btn>
        </template>
      </ws-accordion>

        <template v-for="(block,i) in courseSettings" >
          <v-sheet :style="`border : 1px solid ${wsBACKGROUND}`"
                   :key="`block${i}`"
                   class=" mb-3 wsRoundedLight">
            <div v-if="!block.visibilityField || (entityData[block.visibilityField] === block.visibilityFieldCondition)"  :key="`block${i}`">
            <template v-for="(item,j) in block.items" >
              <v-expand-transition :key="`item${i}${j}`" >
                <div v-if="
              (!item.visibilityField    || (entityData[item.visibilityField] === item.visibilityFieldCondition)) &&
              (!item.invisibilityField  || (entityData[item.invisibilityField] !== item.invisibilityFieldCondition) ) &&
              (!item.onlyNoPaymentPlans || (!entityData.has_payment_plans) )"
                >


                  <div class="">

                    <!-- ::  SETTINGS ROW ::  -->
                    <div class="pa-4  align-center justify-space-between"
                         :class="[{'d-flex' : !SM}]"
                    >
                      <!-- ::  Icon Name Description ::  -->
                      <div class="d-flex">
                        <v-icon size="20" :color="wsATTENTION" class="mr-3"> {{ item.icon }} </v-icon>
                        <div>
                          <h4 style="font-size: 16px; font-weight: 600"  >{{ item.name  }}</h4>
                          <h5 style="max-width: 500px !important" class="font-weight-light grey--text text--darken-3">{{  item.description }}</h5>
                        </div>
                      </div>
                      <!-- ::  Selectors and fields ::  -->
                      <div :class="[{'mt-4' : SM}]">
                        <div v-if="item.type === 'ftSelect'"  >
                          <ft-select
                              @input="checkSelectionAction(item)"
                              :items="item.selectItems"
                              v-model="entityData[item.value]"
                              isBoolean
                              class="pointer"
                              :searchable="item.selectItems.length > 7"
                          >
                            <template #default="{text}">
                              <v-chip
                                  :color="wsACCENT"
                                  :style="
                                  `opacity : ${!entityData[item.value] ? '0.5' : '1'};
                                   ${SM ? 'width: 100%' : ''}`"
                                  class="px-1 font-weight-medium pointer"
                                  :class="SM ? 'justify-center' : 'justify-end'"
                                  dark
                              >
                                <v-icon
                                    class="mr-1"
                                    :class="[{'ml-3' : !['mdi-checkbox-marked-circle-outline','mdi-close-circle-outline'].includes(getValueText( item.selectItems,entityData[item.value], true )) }]"
                                >
                                  {{  getValueText( item.selectItems,entityData[item.value], true ) }}
                                </v-icon>

                                <h5 class="mx-2">{{  text  }}</h5>

                                <v-icon >mdi-menu-down</v-icon>
                              </v-chip>
                            </template>
                          </ft-select>
                        </div>
                        <v-sheet v-else-if="item.type === 'textField'" color="transparent"   class="mr-n3">
                          <ws-text-field
                              v-model="entityData[item.value]"
                              rounded
                              :width="!SM ? '150' : ''"
                              class="compact-form"
                              :placeholder="item.placeholder"
                              :append-icon="item.appendIcon"
                              hide-details
                              outlined
                              :number="item.number"
                              dense
                          >
                            <template v-if="item.appendText"  #append>
                              <h5 style="padding-top: 6px">{{  item.appendText  }}</h5>
                            </template>
                          </ws-text-field>
                        </v-sheet>


                        <div class="d-flex align-center" v-if=" item.type === 'langSelector'">

                          <ws-select
                              :items="LANGUAGES_SELECT"
                              v-model="entityData.langs"
                              multiple
                              autocomplete
                              width="250px"
                              @input="validateLangs"
                          />

                        </div>

                      </div>
                    </div>

                    <!-- Additional Parametrs-->

                    <div v-if="item.value === 'is_subscription' && entityData.is_subscription " class="px-4 pb-4 pl-12">

                      <div class="d-flex justify-space-between">
                        <h4>{{  $t('courses.settings.subscription.type')  }}</h4>
                        <ft-select  :items="subscriptionTypes" v-model="entityData.subscription_type" class="pointer">
                          <template #default="{text}">
                            <v-chip
                                :color="wsACCENT"
                                :style="
                                  `opacity : ${!entityData[item.value] ? '0.5' : '1'};
                                   ${SM ? 'width: 100%' : ''}`"
                                class="px-1 font-weight-medium pointer"
                                :class="SM ? 'justify-center' : 'justify-end'"
                                dark
                            >
                              <v-icon
                                  class="mr-1"
                                  :class="[{'ml-3' : !['mdi-checkbox-marked-circle-outline','mdi-close-circle-outline'].includes(getValueText( item.selectItems,entityData[item.value], true )) }]"
                              >
                                mdi-cog
                              </v-icon>

                              <h5 class="mx-2">{{  text  }}</h5>

                              <v-icon >mdi-menu-down</v-icon>
                            </v-chip>
                          </template>
                        </ft-select>
                      </div>

                      <div v-if="entityData.subscription_type === 'time_period' " class="d-flex justify-space-between mt-5">
                        <h4>{{  $t('TimePeriod')  }}</h4>
                        <ft-select  :items="timePeriodTypes" v-model="entityData.time_period_type" class="pointer">
                          <template #default="{text}">
                            <v-chip
                                :color="wsACCENT"
                                :style="
                                  `opacity : ${!entityData[item.value] ? '0.5' : '1'};
                                   ${SM ? 'width: 100%' : ''}`"
                                class="px-1 font-weight-medium pointer"
                                :class="SM ? 'justify-center' : 'justify-end'"
                                dark
                            >
                              <v-icon
                                  class="mr-1"
                                  :class="[{'ml-3' : !['mdi-checkbox-marked-circle-outline','mdi-close-circle-outline'].includes(getValueText( item.selectItems,entityData[item.value], true )) }]"
                              >
                                mdi-cog
                              </v-icon>

                              <h5 class="mx-2">{{  text  }}</h5>

                              <v-icon >mdi-menu-down</v-icon>
                            </v-chip>
                          </template>
                        </ft-select>
                      </div>

                      <div v-if="entityData.subscription_type === 'time_period' && entityData.time_period_type === 'custom' " class="d-flex justify-space-between mt-5">
                        <h4>{{  $t('courses.settings.subscription.period_days')  }}</h4>
                        <ws-text-field v-model="entityData.time_period_days"
                                       rounded
                                       :width="!SM ? '150' : ''"
                                       class="compact-form"
                                       :placeholder="item.placeholder"
                                       :append-icon="item.appendIcon"
                                       hide-details
                                       outlined
                                       number
                                       number-length="3"
                                       @input="validatePeriodDays"
                                       dense >
                          <template v-if="item.appendText"  #append>
                            <h5 style="padding-top: 6px">{{  item.appendText  }}</h5>
                          </template>
                        </ws-text-field>
                      </div>

                      <div v-if="entityData.subscription_type === 'time_period'" class="mt-5 pr-8">
                        <h4>{{ $t('courses.settings.subscription.periods_amount') }} : </h4>

                        <v-sheet class="mt-5"  >
                            <v-row>
                              <v-col v-for="i in 24" :key="i" cols="1">
                                <ws-check-box @input="selectTimePeriodAmount(i)" :value="getTimePeriodValue(i)" :label="$t('Custom')"><h5>{{ i }}</h5></ws-check-box>
                              </v-col>
                            </v-row>
                        </v-sheet>

                      </div>

                    </div>

                  </div>
                  <v-divider
                      v-if="block.items.length > 1 && j < block.items.length-1 "
                      :style="`border-color :  ${wsBACKGROUND}`"
                      :key="`item${i}${j}`" />


                </div>
              </v-expand-transition>
            </template>
            </div>
          </v-sheet>
        </template>

      <v-btn @click="openConfirmDelete" class="noCaps mt-5" text :color="wsWARNING">
        <v-icon class="mr-1">mdi-delete</v-icon>
        {{  $t('Delete')  }}
      </v-btn>

      <v-sheet height="150"></v-sheet>

    </v-sheet>

    <ws-dialog
        v-model="displayConfirmDelete"
        @save="deleteCourse"
        :save-text="$t('Delete')"
        :title="$t('DeleteCourse')"
    >
      <h5 class="mb-5 text-center"> {{ $t('ConfirmCourseDeleteMessage') }}</h5>
      <h5 class="mb-5 text-center"> {{ $t('ConfirmCourseDeleteInstruction') }}</h5>

      <h4 class="mb-5 text-center"> {{ entityData.uuid }}</h4>
      <ws-text-field
          v-model="confirmDeleteData"
      />

    </ws-dialog>


  </sub-page>
</template>

<script>
import {mapActions, mapMutations , mapState} from "vuex";
import languagesList from "@/mixins/languagesList";

export default {
  name: "courseSettings",
  props : ['uuid'],
  mixins : [languagesList],
  data() {
    return {
      displayConfirmDelete : false,
      confirmDeleteData : '',
      entityData : {
        short_description : {},
        description : {},
        name : {},
        langs : []
      }
    }
  },
  computed : {
    ...mapState('courses', [ 'course' ]),

    timePeriodTypes() {
      return [
        { text : this.capitalizeFirstChar(this.$tc('_month',0) )  , value : 'month'   },
        { text : this.$t('Year_')   , value : 'year'     },
        { text : this.$t('Custom') , value : 'custom' }
      ]
    },

    subscriptionTypes() {
      return [
        { text : this.$t('TimePeriod') , value : 'time_period' }
      ]
    },

    priceDescriptionSubscriptionText() {
      if ( !this.entityData.is_subscription ) {
        return ''
      }

      if ( this.entityData.subscription_type === 'time_period' ) {
        if ( this.entityData.time_period_type === 'month' ) {
          return ' ' + this.$tc('perMonths' , 1)
        }
        if ( this.entityData.time_period_type === 'year' ) {
          return ' ' + this.$tc('perYears' , 1)
        }
        if ( this.entityData.time_period_type === 'custom' ) {
          return ' ' + this.$tc('perDays' , this.entityData.time_period_days)
        }
      }

      return ''

    },
    courseSettings() {
      return [
        {
          name : this.$t('General') ,
          items : [
            {
              icon : 'mdi-school-outline',
              name : this.$t('courses.settings.status.title'),
              description : this.$t('courses.settings.status.text'),
              value : 'active',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('Active') ,   value : true  ,   icon : 'mdi-checkbox-marked-circle-outline'   },
                { text : this.$t('NotActive'), value : false ,   icon : 'mdi-close-circle-outline'             }
              ],
              visibility : true,
              noExpand : true
            },
            {
              icon : 'mdi-shopping-outline',
              name : this.$t('courses.settings.market.title'),
              description : this.$t('courses.settings.market.text'),
              value : 'market',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('Active') ,   value : true  ,   icon : 'mdi-checkbox-marked-circle-outline'   },
                { text : this.$t('NotActive'), value : false ,   icon : 'mdi-close-circle-outline'             }
              ],
              visibility : true,
              noExpand : true
            },
            {
              icon : 'mdi-eye',
              name : this.$t('courses.settings.is_hidden.title'),
              description : this.$t('courses.settings.is_hidden.text'),
              value : 'is_hidden',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('Hidden') ,   value : true  ,   icon : 'mdi-eye-off-outline'   },
                { text : this.$t('Visible'), value : false ,   icon : 'mdi-eye-outline'             }
              ],
              visibility : true,
              noExpand : true
            }
          ]
        },
        {
          name : this.$t('Language') ,
          items : [
            {
              icon : 'mdi-translate',
              name : this.$t('courses.settings.language.title'),
              description : this.$t('courses.settings.language.text'),
              value : 'lang',
              type : 'ftSelect' ,
              selectItems : this.LANGUAGES_SELECT,
              visibility : true,
              noExpand : true
            },
            {
              icon : 'mdi-translate-variant',
              name : this.$t('courses.settings.languages.title'),
              description : this.$t('courses.settings.languages.text'),
              value : 'langs',
              type : 'langSelector' ,
              selectItems : this.LANGUAGES_SELECT,
              visibility : true,
              noExpand : true
            }
          ]
        },
        {
          name : this.$t('Certificates') ,
          items : [
            {
              icon : 'mdi-certificate',
              name : this.$t('courses.settings.certificate.title'),
              description : this.$t('courses.settings.certificate.text'),
              value : 'has_certificates',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('Active') ,   value : true  ,   icon : 'mdi-checkbox-marked-circle-outline'   },
                { text : this.$t('NotActive'), value : false ,   icon : 'mdi-close-circle-outline'             }
              ],
              visibility : true,
              noExpand : true
            }
          ]
        },

        {
          name : this.$t('Payment') ,
          items : [
            {
              icon : 'mdi-currency-usd',
              name : this.$t('Payment'),
              description : this.$t('PaymentDescription'),
              value : 'payed',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('Free') , value : false , icon : 'mdi-checkbox-marked-circle-outline'  },
                { text : this.$t('Payed'), value : true  , icon : 'mdi-close-circle-outline'            }
              ],
              visibility : true,
              noExpand : true
            },
            {
              icon : 'mdi-repeat-variant',
              name : this.$t('CourseRepurchase'),
              description : this.$t('CourseRepurchaseDescription'),
              value : 'second_purchase_reset',
              type : 'ftSelect' ,
              visibilityField : 'is_subscription',
              visibilityFieldCondition : false,
              invisibilityField : 'online',
              invisibilityFieldCondition : true,
              selectItems : [
                { text : this.$t('ResetCourse') , value : true , icon : 'mdi-repeat-variant'  },
                { text : this.$t('ProceedCourse'), value : false  , icon : 'mdi-skip-next'            },


              ],
              visibility : true,
              noExpand : true
            },
            {
              icon : 'mdi-cash-multiple',
              name : this.$t('PaymentPlans'),
              description : this.$t('PaymentPlansDescription'),
              value : 'has_payment_plans',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('NoPaymentPlans') , value : false , icon : 'mdi-close-circle-outline'             },
                { text : this.$t('PaymentPlansOn') , value : true  , icon : 'mdi-checkbox-marked-circle-outline'     }
              ],
              visibilityField : 'payed',
              visibilityFieldCondition : true,
              noExpand : true
            },
            {
              icon : 'mdi-calendar',
              name : this.$t('courses.settings.subscription.title'),
              description : this.$t('courses.settings.subscription.text'),
              value : 'is_subscription',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('NotActive') , value : false , icon : 'mdi-close-circle-outline'             },
                { text : this.$t('Active') , value : true  , icon : 'mdi-checkbox-marked-circle-outline'     }
              ],
              visibilityField : 'payed',
              visibilityFieldCondition : true,
              noExpand : true
            },
            {
              icon : 'mdi-cash-multiple',
              name : this.$t('Price'),
              placeholder : this.$t('Price'),
              description : this.$t('courses.settings.price.text') + this.priceDescriptionSubscriptionText,
              value : 'price',
              type : 'textField' ,
              number : true,
              appendText : this.BUSINESS_CURRENCY,
              visibilityField : 'payed',
              visibilityFieldCondition : true,
              invisibilityField : 'has_payment_plans',
              invisibilityFieldCondition : true,
              noExpand : true
            }
          ]
        },
        {
          name : this.$t('Course_') ,
          items : [
            {
              icon : 'mdi-calendar',
              name : this.$t('courses.settings.online.title'),
              description : this.$t('courses.settings.online.text'),
              value : 'online',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('courses.settings.online.true'), value : true   , icon : 'mdi-cast-education' },
                { text : this.$t('courses.settings.online.false'), value : false , icon : 'mdi-laptop'    },
              ],
              noExpand : true
            },
            {
              icon : 'mdi-key',
              name : this.$t('ContentAccessLimits'),
              description : this.$t('ContentAccessLimitsDescription'),
              value : 'has_access_rules',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('DaysAfterFinish'), value : true   , icon : 'mdi-calendar-check' },
                { text : this.$t('ScheduledCourse'), value : false , icon : 'mdi-calendar'    },
              ],
              visibilityField : 'online',
              visibilityFieldCondition : true,
              onlyNoPaymentPlans : true,
              noExpand : true
            },
            {
              icon : 'mdi-key',
              name : this.$t('ContentAccessLimits'),
              description : this.$t('ContentAccessLimitsDescription'),
              value : 'has_access_rules',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('LimitedByDays'), value : true   , icon : 'mdi-calendar' },
                { text : this.$t('Unlimited'), value : false , icon : 'mdi-human'    },
              ],
              visibilityField : 'online',
              visibilityFieldCondition : false,
              onlyNoPaymentPlans : true,
              noExpand : true
            },
            {
              icon : 'mdi-calendar-month',
              name : this.$t('TotalDays'),
              placeholder : this.$t('Days'),
              description : this.$t('TotalDaysDescription'),
              value : 'access_days',
              type : 'textField' ,
              appendText : this.$t('Days'),
              visibilityField : 'has_access_rules',
              visibilityFieldCondition : true,
              invisibilityField : 'online',
              invisibilityFieldCondition : true,
              onlyNoPaymentPlans : true,
              noExpand : true
            },
            {
              icon : 'mdi-calendar-month',
              name : this.$t('AccessDaysAfterSchedule'),
              placeholder : this.$t('Days'),
              description : this.$t('AccessDaysAfterScheduleDescription'),
              value : 'access_days',
              type : 'textField' ,
              appendText : this.$t('Days'),
              visibilityField : 'has_access_rules',
              visibilityFieldCondition : true,
              invisibilityField : 'online',
              invisibilityFieldCondition : false,
              onlyNoPaymentPlans : true,
              noExpand : true
            },
            // {
            //   icon : 'mdi-calendar-month',
            //   name : this.$t('CourseStart'),
            //   subtitle : this.$t('CourseAccesssubtitle'),
            //   value : 'scheduled',
            //   type : 'ftSelect' ,
            //   visibilityField : 'online',
            //   visibilityFieldCondition : true,
            //   selectItems : [
            //     { text : this.$t('ScheduledCourse'), value : true  , icon : 'mdi-calendar'},
            //  //   { text : this.$t('OnGroupsCourse'),  value : false , icon : 'mdi-account-multiple' },
            //   ],
            // },
            {
              icon : 'mdi-flag-outline',
              name : this.$t('StopCourse'),
              description : this.$t('StopCourseDescription'),
              value : 'is_stop_course',
              type : 'ftSelect' ,
              selectItems : [
                 { text : this.$t('Active') ,   value : true  ,   icon : 'mdi-checkbox-marked-circle-outline'   },
                 { text : this.$t('NotActive'), value : false ,   icon : 'mdi-close-circle-outline'             }
              ],
              noExpand : true
            },
          ]
        },
        {
          name : this.$t('Comments') ,
          items : [
            {
              icon : 'mdi-forum',
              name : this.$t('courses.settings.comments.title'),
              description : this.$t('courses.settings.comments.text'),
              value : 'has_comments',
              type : 'ftSelect' ,
              selectItems : [
                { text : this.$t('Active') ,   value : true  ,   icon : 'mdi-checkbox-marked-circle-outline'   },
                { text : this.$t('NotActive'), value : false ,   icon : 'mdi-close-circle-outline'             }
              ],
              visibility : true,
              noExpand : true
            }
          ]
        },
        // {
        //   name : this.$t('Groups') ,
        //   visibilityField : 'online',
        //   visibilityFieldCondition : true,
        //   items : [
        //     {
        //       name : this.$t('Groups'),
        //       subtitle : this.$t('Groupssubtitle'),
        //       value : 'has_groups',
        //       type : 'ftSelect' ,
        //       selectItems : [
        //         { text : this.$t('HasGroups'), value : true , icon : 'mdi-account-filter' },
        //         { text : this.$t('NoGroups'), value : false , icon : 'mdi-account-multiple'},
        //       ],
        //     },
        //     {
        //       name : this.$t('GroupsLimit'),
        //       subtitle : this.$t('GroupsLimitsubtitle'),
        //       value : 'groups_limit',
        //       type : 'ftSelect' ,
        //       visibilityField : 'has_groups',
        //       visibilityFieldCondition : true,
        //       selectItems : [
        //         { text : this.$t('Limited'),  value : true  , icon : 'mdi-format-list-checks' },
        //         { text : this.$t('NoLimits'), value : false , icon : 'mdi-format-list-checkbox'},
        //       ],
        //     },
        //     { name : this.$t('GroupsMinAmount'),
        //       placeholder : this.$t('Number'),
        //       subtitle : this.$t('GroupsMinAmountsubtitle'),
        //       value : 'groups_min',
        //       type : 'textField' ,
        //       appendIcon : 'mdi-account-multiple',
        //       visibilityField : 'groups_limit',
        //       visibilityFieldCondition : true,
        //     },
        //     { name : this.$t('GroupsMaxAmount'),
        //       placeholder : this.$t('Number'),
        //       subtitle : this.$t('GroupsMaxAmountsubtitle'),
        //       value : 'groups_max',
        //       type : 'textField' ,
        //       appendIcon : 'mdi-account-multiple',
        //       visibilityField : 'groups_limit',
        //       visibilityFieldCondition : true,
        //     }
        //   ]
        // }
      ]
    },


  },
  watch : {
    course(val) {
      this.entityData = val
    },
    'entityData.scheduled'(val) {
      if (!val) {
        this.entityData.has_groups = true
        this.entityData = JSON.parse(JSON.stringify(this.entityData))
      }
    },
    // 'entityData.lang'(val) {
    //   if ( !this.entityData.langs.includes(val)) {
    //     this.entityData.langs.push(val)
    //   }
    // },
  },
  methods : {
    ...mapActions('courses', [ 'ADD_COURSE' , 'DELETE_COURSE' ]),
    ...mapMutations('courses', ['INIT_PAGE']),

    validateLangs(langs) {
      if ( !langs.includes(this.entityData.lang)) {
        this.notify(this.$t('courses.settings.languages.primary_delete_error') , 'warning')
        this.entityData.langs.push(this.entityData.lang)
      }
    },
    validatePeriodDays() {
      if (this.entityData.time_period_days < 1 ) {
        this.entityData.time_period_days = 1
      }
    },
    checkSelectionAction(item) {
      if ( item.value === 'is_subscription' ) {
        if ( this.entityData.is_subscription ) {
          if (!this.entityData.subscription_type ) {
            this.entityData.subscription_type = 'time_period'
          }
          if (!this.entityData.time_period_type ) {
            this.entityData.time_period_type = 'month'
          }

          if ( !this.entityData.time_periods_select ) {
            this.entityData.time_periods_select = [ 1 , 3 , 6 , 12 , 24 ]
          }

          this.entityData = Object.assign({} , this.entityData)

        }
      }
    },

    selectTimePeriodAmount(period) {
      if ( !Array.isArray(this.entityData.time_periods_select) ) {
        this.entityData.time_periods_select = []
        this.entityData = Object.assign({} , this.entityData)
      }
      if ( this.entityData.time_periods_select.includes(period) ) {
        this.entityData.time_periods_select = this.entityData.time_periods_select.filter(el => el !== period )
      } else {
        this.entityData.time_periods_select.push( parseInt(period) )
      }
      this.entityData.time_periods_select = this.entityData.time_periods_select.sort((a, b) => { return a - b })
    },
    getTimePeriodValue(period) {
      if ( !Array.isArray(this.entityData.time_periods_select) ) {
        return false
      }
      return this.entityData.time_periods_select.includes(period)
    },


    openConfirmDelete() {
      this.confirmDeleteData = ''
      this.displayConfirmDelete = true
    },
    async deleteCourse() {
      if ( this.confirmDeleteData !== this.entityData.uuid) {
        return this.notify(this.$t('ConfirmDeleteCourseNameError') , 'warning' )
      }
      let result = await this.DELETE_COURSE(this.entityData.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError') , 'error' )
      }
      this.notify(this.$t('CourseDeleted'))
      this.$router.push(this.businessDashLink('courses'))
    },

    async saveChanges() {

      if (this.entityData.time_period_days < 1 ) {
        this.entityData.time_period_days = 1
      }

      if ( this.entityData.payed && !this.entityData.price && !this.entityData.has_payment_plans ) {
        return this.notify(this.$t('PriceNotSet'))
      }

      let result = await this.ADD_COURSE(this.entityData)
      if (!result) { return this.notify( this.$t('NetworkError') )}
      this.$store.state.courses.course = result;
      this.notify( this.$t('ChangesSaved') )

    },

    async copyRegistrationLink() {
      try {
        await navigator.clipboard.writeText(this.businessPublicLink(`course/enroll/${this.uuid}` , true ));
        this.notify(this.$t('CopiedToClipboard') ,null, 'mdi-clipboard-edit-outline' )
      } catch($e) {
        this.notify(this.$t('Error') )
      }
    },
    // technical

    getValueText(items,value, icon = false) {
      if ( !items ) { return '' }
      if ( items.length === 0) { return '' }
      let item = items.find( el=> el.value === value );
      if (!item) { return '' }
      return !icon ? item.text : item.icon
    }

  },
  mounted() {
    this.entityData = JSON.parse(JSON.stringify(this.course))
  }
}
</script>

<style scoped>

</style>